<template>
    <div class="card mb-4">
        <h5 class="card-header">Zmiana hasła</h5>
        <div class="card-body">
            <form id="formChangePassword" method="POST" onsubmit="return false"
                class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" data-bitwarden-watching="1">
                <div v-if="success" class="alert alert-success" role="alert">
                    <h6 class="alert-heading fw-bold mb-1">Hasło zostało zmienione</h6>
                </div>
                <div v-if="validation" class="alert alert-warning" role="alert">
                    <h6 class="alert-heading fw-bold mb-1">Ensure that these requirements are met</h6>
                    <span>{{ validation }}</span>
                </div>
                <div class="row">
                    <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                        <label class="form-label" for="newPassword">Nowe hasło </label>
                        <div class="input-group input-group-merge has-validation">
                            <input class="form-control" type="password" id="newPassword" name="newPassword"
                                placeholder="············" v-model="password">
                            <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                        </div>
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>

                    <div class="mb-3 col-12 col-sm-6 form-password-toggle fv-plugins-icon-container">
                        <label class="form-label" for="confirmPassword">Potwierź nowe hasło</label>
                        <div class="input-group input-group-merge has-validation">
                            <input class="form-control" type="password" name="confirmPassword" id="confirmPassword"
                                placeholder="············" v-model="password2">
                            <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                        </div>
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div>
                        <button @click.prevent="onSubmit()" type="submit" class="btn btn-primary me-2">Zmień hasło</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from '../../../api/axios';
export default {
    props: ['user_id'],
    data: function () {
        return {
            success: false,
            validation: '',
            password: '',
            password2: '',
            userId: 0,

        }
    },
    created: function () {
        this.userId = this.$store.state.user.id;
        if (this.user_id > 0) { this.userId = this.user_id; }
    },
    methods: {
        onSubmit: function () {
            axios.post('api/v1/user/users/' + this.userId + '/password', {
                password: this.password,
                password2: this.password2
            }).then(() => {
                this.success = true;
            }).catch(error => {
                if (error.response.data.validation) {
                    this.validation = error.response.data.validation
                }
            })
            this.password = '';
            this.password2 = '';
        }
    }
}
</script>