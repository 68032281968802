<template>
    <div class="card mb-4">
        <h5 class="card-header">Usuń konto</h5>
        <div class="card-body">
            <div class="mb-3 col-12 mb-0">
                <div class="alert alert-warning">
                    <h6 class="alert-heading fw-bold mb-1">Czy napewno chcesz usunąć konto?</h6>
                    <p class="mb-0">Uważaj, tej akcji nie można cofnąć.</p>
                </div>
            </div>
            <form id="formAccountDeactivation" onsubmit="return false" class="fv-plugins-bootstrap5 fv-plugins-framework"
                novalidate="novalidate">
                <div class="form-check mb-3">
                    <input v-model="buttonDisable" class="form-check-input" type="checkbox" name="accountActivation"
                        id="accountActivation">
                    <label class="form-check-label" for="accountActivation">Potwierdzam usunięcie konta</label>
                    <div class="fv-plugins-message-container invalid-feedback"></div>
                </div>
                <button :disabled="!buttonDisable" @click.prevent="onSubmit()" type="submit"
                    class="btn btn-danger deactivate-account">Usuń konto</button>
                <input type="hidden">
            </form>
        </div>
    </div>
</template>
<script>
import axios from '../../../api/axios';
export default {
    props: ['user_id'],
    data: function () {
        return {
            success: false,
            buttonDisable: 0,
            validation: '',
            userId: 0,

        }
    },
    created: function () {
        this.userId = this.$store.state.user.id;
        if (this.user_id > 0) { this.userId = this.user_id; }
    },
    methods: {
        onSubmit: function () {
            axios.delete('api/v1/user/users/' + this.userId).then(() => {
                this.success = true;
                this.$router.push('/user');
            }).catch(error => {
                if (error.response.data.validation) {
                    this.validation = error.response.data.validation
                }
            })

        }
    }
}
</script>