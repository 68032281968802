<template>
    <div class="container-xxl flex-grow-1 container-p-y">


        <h4 class="fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Użytkownik  /</span> Bezpieczeństwo
        </h4>
        <div class="row">
            <!-- User Sidebar -->
            <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                <!-- User Card -->
                <UserCardDetail :user="user" :key="user.id"></UserCardDetail>

                <!-- /User Card -->

            </div>
            <!--/ User Sidebar -->


            <!-- User Content -->
            <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                <!-- User Pills -->
                <ul class="nav nav-pills flex-column flex-md-row mb-3">
                    <li class="nav-item"><router-link class="nav-link" :to="'/user/' + user.id"><i
                                class="bx bx-user me-1"></i>Konto</router-link></li>
                    <li class="nav-item"><router-link class="nav-link active" :to="'/user/' + user.id + '/secuity'"><i
                                class="bx bx-lock-alt me-1"></i>Bezpieczeństwo</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" :to="'/user/' + user.id + '/notifications'"><i
                                class="bx bx-bell me-1"></i>Powiadomienia</router-link></li>
                </ul>
                <!--/ User Pills -->

                <!-- Change Password -->

                <UserSeciurityChangePassword :user_id="user_id"></UserSeciurityChangePassword>

                <UserDeleteAccountCard :user_id="user_id"></UserDeleteAccountCard>

                <!--/ Change Password -->

                <!-- Two-steps verification -->
                <!-- <div class="card mb-4">
                    <div class="card-header">
                        <h5 class="card-title mb-2">Two-steps verification</h5>
                        <span class="card-subtitle">Keep your account secure with authentication step.</span>
                    </div>
                    <div class="card-body">
                        <h6>SMS</h6>
                        <div class="d-flex justify-content-between border-bottom mb-4 pb-2">
                            <span>+1(968) 945-8832</span>
                            <div class="action-icons">
                                <a href="javascript:;" class="text-body me-1" data-bs-target="#enableOTP"
                                    data-bs-toggle="modal"><i class="bx bx-edit"></i></a>
                                <a href="javascript:;" class="text-body"><i class="bx bx-trash-alt"></i></a>
                            </div>
                        </div>
                        <p class="mb-0">Two-factor authentication adds an additional layer of security to your account by
                            requiring more than just a password to log in.
                            <a href="javascript:void(0);" class="text-body">Learn more.</a>
                        </p>
                    </div>
                </div> -->
                <!--/ Two-steps verification -->

                <!-- Recent Devices -->
                <UserSeciurityRecentDevices :user_id='user_id'></UserSeciurityRecentDevices>

                <!--/ Recent Devices -->
            </div>
            <!--/ User Content -->
        </div>

        <!-- Modals -->
        <!-- Edit User Modal -->
        <EditUserInformation></EditUserInformation>
        <!--/ Edit User Modal -->

        <!-- Enable OTP Modal -->
        <div class="modal fade" id="enableOTP" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                <div class="modal-content p-3 p-md-5">
                    <div class="modal-body">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="text-center mb-4">
                            <h3 class="mb-5">Enable One Time Password</h3>
                        </div>
                        <h6>Verify Your Mobile Number for SMS</h6>
                        <p>Enter your mobile phone number with country code and we will send you a verification code.</p>
                        <form id="enableOTPForm" class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework"
                            onsubmit="return false" novalidate="novalidate">
                            <div class="col-12 fv-plugins-icon-container">
                                <label class="form-label" for="modalEnableOTPPhone">Phone Number</label>
                                <div class="input-group input-group-merge has-validation">
                                    <span class="input-group-text">+1</span>
                                    <input type="text" id="modalEnableOTPPhone" name="modalEnableOTPPhone"
                                        class="form-control phone-number-otp-mask" placeholder="202 555 0111">
                                </div>
                                <div class="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary me-sm-3 me-1">Submit</button>
                                <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal"
                                    aria-label="Close">Cancel</button>
                            </div>
                            <input type="hidden">
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Enable OTP Modal -->

        <!-- Add New Credit Card Modal -->
        <div class="modal fade" id="upgradePlanModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-simple modal-upgrade-plan">
                <div class="modal-content p-3 p-md-5">
                    <div class="modal-body">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="text-center mb-4">
                            <h3>Upgrade Plan</h3>
                            <p>Choose the best plan for user.</p>
                        </div>
                        <form id="upgradePlanForm" class="row g-3" onsubmit="return false">
                            <div class="col-sm-9">
                                <label class="form-label" for="choosePlan">Choose Plan</label>
                                <select id="choosePlan" name="choosePlan" class="form-select" aria-label="Choose Plan">
                                    <option selected="">Choose Plan</option>
                                    <option value="standard">Standard - $99/month</option>
                                    <option value="exclusive">Exclusive - $249/month</option>
                                    <option value="Enterprise">Enterprise - $499/month</option>
                                </select>
                            </div>
                            <div class="col-sm-3 d-flex align-items-end">
                                <button type="submit" class="btn btn-primary">Upgrade</button>
                            </div>
                        </form>
                    </div>
                    <hr class="mx-md-n5 mx-n3">
                    <div class="modal-body">
                        <h6 class="mb-0">User current plan is standard plan</h6>
                        <div class="d-flex justify-content-between align-items-center flex-wrap">
                            <div class="d-flex justify-content-center me-2 mt-3">
                                <sup class="h5 pricing-currency pt-1 mt-3 mb-0 me-1 text-primary">$</sup>
                                <h1 class="display-3 mb-0 text-primary">99</h1>
                                <sub class="h5 pricing-duration mt-auto mb-2">/month</sub>
                            </div>
                            <button class="btn btn-label-danger cancel-subscription mt-3">Cancel Subscription</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Add New Credit Card Modal -->

        <!-- /Modals -->

    </div>
</template>
<script>
import UserSeciurityChangePassword from './components/UserSeciurityChangePassword.vue';
import UserCardDetail from './components/UserCardDetail.vue';
import EditUserInformation from './components/EditUserInformation.vue';
import UserSeciurityRecentDevices from './components/UserSeciurityRecentDevices.vue';
import UserDeleteAccountCard from './components/UserDeleteAccountCard.vue';

import axios from '../../api/axios';

export default {
    components: {
        UserSeciurityChangePassword,
        UserCardDetail,
        EditUserInformation,
        UserSeciurityRecentDevices,
        UserDeleteAccountCard,
    },
    created: function () {
        this.user_id = this.$route.params.id;
        axios.get('api/v1/user/users/' + this.$route.params.id).then((response) => {
            this.user = response.data;
        })

    },

    data: function () {
        return {
            user: { id: 1 }

        }
    },
    methods: {

    },
}
</script>