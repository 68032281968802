<template>
    <div class="card mb-4">
        <h5 class="card-header">Twoje urządzenia</h5>
        <div class="table-responsive">
            <table class="table border-top">
                <thead>
                    <tr>
                        <th class="text-truncate">Ip</th>
                        <th class="text-truncate">Ostatnia aktywność</th>
                        <th class="text-truncate">Przeglądarka</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="post, index in posts" :key="index">
                        <td class="text-truncate">{{ post.ip_address }}</td>
                        <td class="text-truncate">{{ post.date.date.substr(0,16) }}</td>
                        <td class="text-truncate">{{ post.user_agent }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import axios from '../../../api/axios';

export default {
    props: ['user_id'],
    data: function () {
        return {
            posts: [],
        }
    },
    created: function () {
        this.getPosts();
    },
    methods: {
        getPosts: function () {
            axios.get('api/v1/user/users/' + this.user_id + '/logins?limit=15').then((response) => {
                this.posts = response.data.data;
            })

        }
    }
}
</script>
